<template>
  <v-dialog v-model="dialog" width="auto">
    <v-card max-width="1000" prepend-icon="mdi-lock" title="Privacybeleid">
      <v-card-text>
        <p>
          <b>Soorten persoonsgegevens </b><br />Voor het onderzoek worden volgende persoonsgegevens
          verwerkt: <br />&bull; Naam <br />&bull; Organisatie <br />&bull; E-mailadres
        </p>
        <br />
        <p>
          <b>Doeleinden van verwerking</b><br />De persoonsgegevens kunnen worden verwerkt ten
          behoeve van onderzoek. De verzameling van de gegevens dient verschillende doelen:
          <br />&bull; Gebruikers van het tool krijgen inzicht in hoe zij zelf opereren in
          verhouding tot anderen in het land en in de sector. <br />&bull; Gebruikers van het tool
          wordt perspectief geboden hoe zij meer circulair kunnen inkopen.<br />&bull; Er ontstaat
          een beeld hoe circulair in Nederland wordt ingekocht. Dit beeld kan worden gebruikt voor
          maatregelen in sectoren en/of het hele land bij het vaststellen en aanpassen van beleid en
          maatregelen.
        </p>
        <br />
        <p>
          <b>Grondslagen voor verwerking</b><br />De persoonsgegevens worden verwerkt op grond van:
          <br />&bull; Uw toestemming voor verwerking in voor dit specifieke tool <br />&bull;
          Algemeen belang van wetenschappelijk onderzoek. Uw persoonsgegevens worden alleen verwerkt
          voor de doeleinden waarvoor ze zijn verkregen.
        </p>
        <br />
        <p>
          <b>Toegang tot de gegevens</b><br />De volgende personen hebben toegang tot de gegevens:
          <br />&bull; Erica Goedhart, Hogeschool van Amsterdam <br />&bull; Jordie van
          Berkel-Schoonen, F-Fort <br />&bull; Nicole van Berkel, F-Fort
        </p>
        <br />
        <p>
          <b>Anonimiseren gegevens</b><br />Het Impact Kompas geeft u inzicht en perspectief op
          basis van een door u ingevulde vragenlijst. Deze data wordt anoniem opgeslagen in onze
          database. Dat wil zeggen dat deze gegevens niet te koppelen zijn aan uw identiteit.
        </p>
        <br />
        <p>
          De geanonimiseerde gegevens worden door de HvA gebruikt in het huidige en toekomstige
          onderzoeksprojecten. Deze geanonimiseerde gegevens kunnen ook door de HvA met derden
          worden gedeeld voor onderzoeksdoeleinden. De HvA zal deze persoonsgegevens verwerken voor
          de hierboven gespecificeerde verwerkingsdoeleind(en) en zal hiervoor niet meer gegevens
          verzamelen dan nodig is voor de uitvoering daarvan.
        </p>
        <br />
        <p>
          <b>Bewaartermijnen</b><br />Zolang u gebruik wilt maken van het Impact Kompas, kunt u uw
          account kosteloos gebruiken. Gedurende die termijn bewaren wij uw gegevens.
        </p>
        <br />
        <p>
          Zodra u uw account wenst te be&euml;indigen, kunt u uw account zelf opheffen. Daarop
          worden automatisch uw persoonsgegevens verwijderd uit onze systemen en worden uw
          persoonsgegevens niet meer verwerkt.
        </p>
        <br />
        <p>
          <b>Be&euml;indigd account</b><br />Zodra u uw account heeft be&euml;indigd, kunt u de
          gegevens die u in het verleden heeft ingevoerd niet langer inzien. Omdat uw data
          geanonimiseerd is opgeslagen, kunnen wij deze gegevens ook niet aan u aanleveren.
        </p>
        <br />
        <p>
          Onderzoek vindt plaats binnen beveiligde omgeving van de Hogeschool van Amsterdam Het
          onderzoek is onderdeel van wetenschappelijk onderzoek dat op de Hogeschool van Amsterdam
          (HvA) wordt uitgevoerd. Dit tool is in lijn is met het bredere privacybeleid van de HvA.
          Voor het HvA privacy statement en aanvullende informatie verwijzen wij naar het
          <a href="https://www.hva.nl/home/disclaimers/privacy" target="_blank"
            >privacy statement van de Hogeschool van Amsterdam</a
          >.
        </p>
      </v-card-text>
      <template v-slot:actions>
        <v-btn variant="tonal" text="Ok" @click="dialog = false"></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps(['dialogId']);
const dialog = ref(false);

watch(
  () => props.dialogId,
  () => {
    dialog.value = true;
  }
);
</script>
