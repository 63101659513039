<template>
  <v-btn-rounded @click="handleLogin">Log in</v-btn-rounded>
</template>

<script setup>
import { useAuth0 } from '@auth0/auth0-vue';

const { loginWithRedirect } = useAuth0();

const handleLogin = () => {
  try {
    loginWithRedirect();
  } catch (error) {
    console.log(error);
  }
};
</script>
