<script setup>
import LoginButton from '@/components/Buttons/LoginButton.vue';
import LogoutButton from '@/components/Buttons/LogoutButton.vue';
import SignupButton from '@/components/Buttons/SignupButton.vue';
import { inject, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import AdminNavbar from '../Admin/AdminNavbar.vue';

const router = useRouter();

const isAuthenticated = ref(false);
const isAdmin = ref(false);
const hasCompletedStoredAttempts = ref(false);
const hasCompletedLocalAttempts = ref(false);
const user = ref();

const sessionService = inject('sessionService');
const resultsService = inject('resultsService');
const userService = inject('userService');

onMounted(async () => {
  isAuthenticated.value = sessionService.isUserAuthenticated();
  if (isAuthenticated.value) {
    isAdmin.value = sessionService.isUserAdmin();
    user.value = sessionService.getUser();
  }
  await getHasCompletedAttempts();
});

function navigateToHome() {
  router.push('/');
}

async function getHasCompletedAttempts() {
  const isAuthenticated = sessionService.isUserAuthenticated();
  let isUserKnown = false;
  let userId;
  if (isAuthenticated) {
    userId = sessionService.getUserId();
    isUserKnown = await userService.asyncUserExists(userId);
  }
  if (isUserKnown) {
    const attempts = await resultsService.getCompletedAttempts(userId);
    hasCompletedStoredAttempts.value = attempts && attempts.length > 0;
  } else {
    let storedResults = JSON.parse(localStorage.getItem('questionnaireResults'));
    if (storedResults) {
      hasCompletedLocalAttempts.value = storedResults['isCompleted'] === true;
    }
  }
}
</script>

<template>
  <header>
    <nav>
      <div v-if="isAdmin" class="admin-menu-container">
        <admin-navbar></admin-navbar>
      </div>

      <div id="logos">
        <a id="impact-kompas-logo"
          ><img src="../../assets/logo-header.svg" alt="Wij2030" @click="navigateToHome"
        /></a>
      </div>

      <div id="center_content">
        <RouterLink class="header-item" to="/about">Over ons</RouterLink>
        <RouterLink class="header-item" v-if="hasCompletedStoredAttempts" to="/results"
          >Mijn resultaten</RouterLink
        >
        <RouterLink class="header-item" v-else-if="hasCompletedLocalAttempts" to="/results-guest"
          >Mijn resultaten</RouterLink
        >
        <RouterLink class="header-item" v-else to="/questionnaire">Mijn resultaten</RouterLink>
      </div>
      <div v-if="isAuthenticated" id="welcome">
        <span class="welcome-text">Welkom</span>
        <RouterLink class="header-item username" to="/profile" style="z-index: 4">{{
          user.first_name + ' ' + user.last_name
        }}</RouterLink>
        <LogoutButton style="margin-right: 100px; z-index: 4" />
      </div>
      <div v-if="!isAuthenticated" id="login">
        <SignupButton style="margin-right: 20px; z-index: 4" />
        <LoginButton style="margin-right: 100px; z-index: 4" />
      </div>
    </nav>
  </header>
</template>

<style scoped>
.username {
  position: relative;
  display: inline-block;
  color: #565554;
  text-decoration: none;
  font-weight: bold;
}

.username::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #41b06e; /* Color of the underline */
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.username:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.username:hover {
  color: #41b06e; /* Color of the text on hover */
}

.welcome-text {
  color: #565554;
  font-size: 18px;
}

header {
  background-color: white;
  margin: 0px 30px;
}

header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: visible;
  align-self: center;
}

.header-item {
  color: #565554;
  text-decoration: none;
  padding: 15px;
  font-size: 18px;
  font-weight: normal;
}

#logos {
  display: flex;
  align-items: center;
}

#impact-kompas-logo img {
  width: 400px;
  margin-left: 100px;
  cursor: pointer;
}

#hva-logo img {
  width: 160px;
  height: 28px;
}

#center_content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.admin-menu-container {
  position: absolute;
}
</style>
