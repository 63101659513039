export class QuestionnaireAdaptor {
  fetchService;

  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  async asyncGetActiveQuestionnaire() {
    return this.fetchService.fetchWithAuth('/questionnaire/active', { method: 'GET' }, false);
  }

  async asyncGetByUuid(uuid) {
    return this.fetchService.fetchWithAuth('/admin/questionnaire/' + uuid, {
      method: 'GET'
    });
  }

  async asyncCreateNewVersion(questionnaire) {
    return this.fetchService.fetchWithAuth('/admin/questionnaire', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(questionnaire)
    });
  }

  async asyncDeleteQuestionnaire(questionnaireUuid) {
    return this.fetchService.fetchWithAuth('/admin/questionnaire/' + questionnaireUuid, {
      method: 'DELETE'
    });
  }

  async asyncGetAllQuestionnaires() {
    return this.fetchService.fetchWithAuth('/admin/questionnaire/list', {
      method: 'GET'
    });
  }

  async asyncSetActiveQuestionnaire(uuid) {
    return this.fetchService.fetchWithAuth('/admin/questionnaire/activate/' + uuid, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' }
    });
  }

  async asyncGetUserQuestionnaire(userUuid) {
    return this.fetchService.fetchWithAuth('/attempt/personal/start/' + userUuid, {
      method: 'GET'
    });
  }

  async asyncFinishQuestionnaire(attemptUuid) {
    return this.fetchService.fetchWithAuth('/attempt/personal/finish/' + attemptUuid, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' }
    });
  }

  async asyncGetUserProgress(userUuid) {
    const api = '/attempt/personal/progress/' + userUuid;

    return await this.fetchService.fetchWithAuth(
      api,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      },
      true,
      'text'
    );
  }

  async asyncDeleteUserProgress(userUuid) {
    return this.fetchService.fetchWithAuth('/attempt/personal/' + userUuid, {
      method: 'DELETE'
    });
  }

  async asyncFindAllSubCategories() {
    return this.fetchService.fetchWithAuth('/admin/subCategory', {
      method: 'GET'
    });
  }
}
