<template>
  <div id="footer">
    <div id="cooperation">
      <div>
        <a href="https://wij2030.nl" target="”_blank”">
          <img id="wij2030-logo" src="../../assets/wij2030logo.png" />
        </a>
      </div>
      <div>
        <div>
          <p style="margin-bottom: 15px">Een trotse samenwerking van</p>
        </div>
        <div>
          <a href="https://www.hva.nl/" target="”_blank”">
            <img
              src="../../assets/hva-logo-compact.png"
              class="linked-logo"
              alt="HvA"
              style="margin-right: 50px"
            />
          </a>
          <a href="https://f-fort.nl/" target="”_blank”">
            <img src="../../assets/ffort-logo.png" class="linked-logo" alt="F-Fort" />
          </a>
        </div>
      </div>
    </div>

    <div>
      <a href="mailto:info@wij2030.nl" style="color: #4caf50">Contact</a>
    </div>

    <div id="privacy-container">
      <button @click="increaseDialogId">Privacybeleid</button>
    </div>
  </div>

  <privacyStatement :dialog-id="dialogId"></privacyStatement>
</template>

<script setup>
import { ref } from 'vue';
import privacyStatement from './privacy-statement.vue';

const dialogId = ref(0);

function increaseDialogId() {
  dialogId.value = dialogId.value + 1;
}
</script>

<style scoped>
#footer {
  font-size: 18px;
  color: #565554;
  height: 9rem;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  background-color: #e8f5e9;
}

#privacy-statement {
  text-decoration: none;
  color: inherit;
}

#privacy-container {
  text-align: right;
  color: #4caf50;
  margin-right: 40px;
}

#cooperation {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

#wij2030-logo {
  width: 100px;
  margin-left: 40px;
  margin-right: 40px;
}

.linked-logo {
  max-height: 60px;
  max-width: 130px;
  margin-bottom: 20px;
  vertical-align: middle;
}
</style>
