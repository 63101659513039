import { h } from 'vue';
import compassIcon from './CompassIcon.vue';

const customSvgNameToComponent = {
  compassIcon
};

const customSVGs = {
  component: (props) => h(customSvgNameToComponent[props.icon])
};

export { customSVGs };
