<template>
  <svg id="Laag_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 165.73 167.19">
    <path
      d="M121.78,52.91c-.76-1-2.05-1.42-3.26-1.08l-87.4,25.23c-1.32.38-2.22,1.6-2.19,2.97.02,1.37.96,2.56,2.29,2.89l38.36,9.64-.02,38.69c0,1.36.9,2.55,2.21,2.92.27.08.55.11.82.11,1.04,0,2.03-.53,2.59-1.45l46.77-76.5c.65-1.07.58-2.43-.18-3.42Z"
      fill="#fff"
    />
    <path
      d="M82.02,153.81c-1.35,0-2.77-.04-4.21-.13-2.84-.17-5-2.61-4.84-5.45.17-2.84,2.62-5,5.45-4.84,1.24.07,2.46.11,3.6.11,33.04,0,59.92-26.88,59.92-59.92s-26.88-59.92-59.92-59.92c-28.01,0-52.01,19.02-58.35,46.26-.65,2.77-3.41,4.49-6.19,3.85-2.77-.65-4.49-3.41-3.85-6.19,3.54-15.18,12.21-28.95,24.43-38.76,12.4-9.97,28.01-15.46,43.96-15.46,38.72,0,70.22,31.5,70.22,70.22s-31.5,70.22-70.22,70.22Z"
      fill="#fff"
    />
  </svg>
</template>
