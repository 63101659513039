export class WebsiteTextAdaptor {
  fetchService;

  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  async asyncCreateWebsiteInformation(pageName, data) {
    return this.fetchService.fetchWithAuth(
      '/admin/websiteInformation/' + pageName,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      },
      false
    );
  }

  async asyncGetWebsiteInformation(pageName) {
    return this.fetchService.fetchWithAuth(
      '/websiteInformation/' + pageName,
      {
        method: 'GET'
      },
      false
    );
  }

  async asyncUpdateWebsiteInformation(uuid, data) {
    return this.fetchService.fetchWithAuth(
      '/admin/websiteInformation/' + uuid,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      },
      true
    );
  }

  async asyncUpdateWebsiteInformationImage(uuid, data) {
    return this.fetchService.fetchWithAuth(
      '/admin/websiteInformation/' + uuid + '/image',
      {
        method: 'PUT',
        body: data
      },
      false
    );
  }
}
